import { useEffect } from "react"

const AutumnAnimation = () => {



  useEffect(()=> {
    // UPDATE COLORS OF BACKGROUNDS


    switch (window.location.pathname) {
      case "/panorama":
        const panorama = document.getElementById("panorama_ID")
        panorama!.style.background = "linear-gradient(#123, rgb(6, 16, 26))"
        break;
      case "/obyvacka_bar":
        /* const obyvacka_bar = document.getElementById("obyvackaBar_ID")AUTUMN_ANIMATION_ID
        obyvacka_bar!.style.background = "linear-gradient(rgba(17, 34, 51, 0.33), rgba(0, 0, 0, 0))" */
        const obyvacka_bar = document.getElementById("AUTUMN_ANIMATION_ID")
        obyvacka_bar!.style.display = "none"
        break;
      case "/jazz_cafe":
        const jazz_cafe = document.getElementById("jazzCafe_ID")
        jazz_cafe!.style.background = "linear-gradient(#0000008d, rgba(0, 0, 0, 0))"
        break;
      case "/predna_hora":

        break;
      case "/just_lovely_cafe":
        const just_lovely_cafe = document.getElementById("AUTUMN_ANIMATION_ID")
        just_lovely_cafe!.style.display = "none"
        break;

      default:
        break;
    }
  },[])


  // 80 leafs
  return (
    <div id="AUTUMN_ANIMATION_ID" className="winter_animation autumn_animation">
      <div className="leaf leaf_1"></div>
      <div className="leaf leaf_2"></div>
      <div className="leaf leaf_1"></div>
      <div className="leaf leaf_1"></div>
      <div className="leaf leaf_2"></div>
      <div className="leaf leaf_1"></div>
    </div>
  )
}

export default AutumnAnimation