import { IoMdArrowRoundBack } from "react-icons/io"
import { justLovelyCafeMenu } from "../../content/JustLovelyCafe/menu"

export const justLovelyCafeConfig = {
  isRestaurant: false,
  menuUpdating: false,
  menu: justLovelyCafeMenu,
  /* default logo './assets/images/clients/logos/just_lovely_cofe.png' */
  logo: require("../../assets/images/clients/logos/just_lovely_cofe.png"),
  /* logo: require("../../assets/images/clients/logos/halloween/just_lovely_cofe_halloween.png"), */
  /* logo: require("../../assets/images/clients/logos/christmas/just_lovely_cofe_christmas.png"), */

  // after click on section screen scrolls to that section
  autoscrollAfterClick: false,
  scrollToTopAferOpen: true,
  // left alignment if displayed in rows
  alignTitleToLeft: false,
  //scroll time of scroll to section
  autoscrollTimeInMs: 100,
  //scroll to top of page after closing section
  scrollToTopAfterClose: true,
  //icon that appears in corner when section is opened
  hideAllIcon: IoMdArrowRoundBack,
  //decides if other sections should dissapear when section is opened
  hideAllWhenOneOpen: true,
  //vibrate on click on section
  vibrateOnClick: false,
  // close previous section when new section is opened
  closePreviousSectionOn: true,
  //margin top to display all sections
  displayAllOnPhone: false,
  //contact icon color
  iconColor: "#201F1D",
  //links to social sites
  facebook: "https://www.facebook.com/justlovelycafe",
  instagram: "https://www.instagram.com/justlovelycafe/",
  email: "justlovelycafe@gmail.com",
  phone: "+421 911 211 252",

  //styles
  mainWrapClass: "justLovelyCafe",
  bottomNote: ""
  /*
  logoBorderRadius: "8px",
  background: "#CFCAC4",
  blockBackgroundColor: "#878787",
  dividerColor: "#F7C808", */


}
